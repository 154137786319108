.Content {
    margin-top: 130px;
}

.Logo {
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 3px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Logo img {
  width: 100px;
}

.Footer-bg{
    width: 100%;
    position: fixed;
    bottom: 0px;
    align-content: center;
    padding: 5px 0px;
    box-sizing: border-box;
    z-index: 90;
    height: 30px;
    text-align: center;

}
.Toolbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
}

.AppBar-bg, .Footer-bg{
    background-image: linear-gradient(to right, white,lightGray, lightGray, gray) !important;
}

@media (max-width: 768px) {
    .DesktopOnly {
        display: none;

    }
    .AppBar-bg {
        height: 70px;
    }
    .Logo{
        top: 0px !important;
        margin-bottom: 0px !important;
    }
    .Logo img{
      width: 75px !important;
    }

}
@media (min-width: 500px) {
  .NavigationItems {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    vertical-align: bottom;
    justify-content: flex-end;
    margin-top: 0px;
    height: 70px;
    
  }

  .ButtonContainer {
    margin-left: 0px;
  }
}